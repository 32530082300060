<template>
  <div>
  <devices />
  </div>
</template>

<script>
import Devices from "@/views/custom_app/components/devices/Devices.vue";
export default {
  components: {
    Devices,
  },
  data() {
    return {
    }
  },
  created() {

  },
}
</script>

<style lang="scss">
</style>
